import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

//page building utils
import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"
import VimeoEmbed from "components/videos/video-vimeo"

// content blocks
import LatestArticles from "components/blocks/LatestArticles"

import { ArrowRightIcon } from '@heroicons/react/solid'

// set links for hero mini nav
const heroNav = [
  { name: "ENVI-Marine™", href: "/envi-marine/" },
  { name: "How it Works", href: "/envi-marine/how-does-it-work/" },
]

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <Seo title="Home" />

      {/* Hero Banner */}
      <ImgBackground image={data.mainhero}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-128 lg:flex">
          <TitleBox
            caption="Pacific Green Marine Technologies"
            title="Game changing scrubber technology."
          />

          <div className="flex items-end lg:w-1/3">
            <div className="w-full flex flex-col space-y-4 m-4">
              {heroNav.map((item, key) => (
                <Link key={key} to={item.href} className="flex justify-between items-center bg-primary-500 rounded-br-lg text-white drop-shadow-md text-2xl xl:text-3xl p-4 py-6">
                  {item.name}<ArrowRightIcon className="h-5 w-5 text-white" />
                </Link>
              ))}
            </div>
          </div>

        </div>
      </ImgBackground>


      {/* intro panel */}
      <section className="py-16">
        <div className="container">

          <div className="lg:flex">
            <div className="w-full mb-12 lg:w-1/2 lg:mb-0 pr-12">
              <h2 className="font-medium text-3xl mb-8 mt-4 lg:mt-12">
                World beating technology, one of the smallest and most flexible footprints on the market, high quality engineering and swift installation, these are just some of the reasons why Pacific Green’s Envi-Marine™ device has been installed on more than 120 ships.
              </h2>
              <p className="text-1xl mb-8">
                As leaders in the field, Pacific Green has proven experience, outstanding performance and the aftercare service essential to both fit and maintain your vessel’s EGCS ensuring the real benefits of reduced fuel costs and low emissions.
              </p>
              <p className="text-sm">
              <Link to="/about-us/" className="text-primary underline mr-8 inline-block">About Us</Link>
              <Link to="/envi-marine/" className="text-primary underline mr-8 inline-block">Our Scrubbing Technology</Link>
              <Link to="/about-us/contact-us/" className="text-primary underline inline-block">Contact Us</Link>
              </p>
            </div>
            <div className="w-full lg:w-1/2">
              <VimeoEmbed src="https://player.vimeo.com/video/328635431?h=a82477dacf" title="ENVI-Marine Process Overview" className="mt-12" />
            </div>
          </div>

        </div>
      </section>

      {/* Latest articles */}
      <section className="border-t border-gray-50 py-16">
        <LatestArticles />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    mainhero: file(
      relativePath: { eq: "backgrounds/oil-tanker-waves-storm.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 2000)
      }
    }
  }
`

export default IndexPage
