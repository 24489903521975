import React from "react"

import classNames from "classnames"


const VimeoEmbed = ({ className, src, title }) => {

    const classes = classNames(className, "aspect-w-16 aspect-h-9")

    return (
        <>
            <div className={classes}>
                <iframe 
                src={src} 
                frameborder="0" 
                allow={'autoplay; fullscreen; picture-in-picture'} 
                allowfullscreen 
                title={title}></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </>
    )
}

export default VimeoEmbed



